
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { defineComponent } from "vue";
import { DxScrollView } from "devextreme-vue/scroll-view";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import api from "@/services/api";
import DataSource from "devextreme/data/data_source";
import DxTagBox from "devextreme-vue/tag-box";
import store from "@/store";

export default defineComponent({
  components: {
    DxPopup,
    DxToolbarItem,
    DxScrollView,
    DxLoadPanel,
    DxTagBox,
  },
  props: {
    planId: { required: true },
    isFilter: { required: true },
  },
  methods: {
    getDataSource(array: any) {
      let data = new DataSource({
        paginate: true,
        pageSize: 10,
        store: {
          type: "array",
          data: array,
        },
      });
      return data;
    },
    show(visible: boolean) {
      if (visible == true) {
        this.popupVisible = true;
        this.changeFilterValues(true, null);
      } else {
        this.popupVisible = false;
      }
    },
    onApply(): void {
      this.show(false);
      let filter = {};
      for (const [key, val] of Object.entries(this.selectedValues)) {
        if ((val as any).length != 0) filter[key] = val;
      }
      if (Object.keys(filter).length != 0) {
        this.$emit("apply-filter", filter);
      } else {
        this.resetFilter();
      }
    },
    onCancel(): void {
      this.show(false);
    },
    resetFilter(): void {
      this.show(false);
      this.selectedValues = {};
      this.$emit("reset-filter");
    },

    async changeFilterValues(show: any, id: any) {
      if (!this.popupVisible) return;
      if (this.isFilterLoading) return;

      this.isFilterLoading = true;
      let isFilterSelected = false;

      if (Object.values(this.selectedValues).flat(1).length != 0)
        isFilterSelected = true;

      if (!isFilterSelected) {
        await this.getFilterValues();
      }

      let savedFilter = show ? this.checkFilters() : null;

      if (show) {
        this.initialValues = {};
        Object.keys(this.selectedValues).filter((key) => {
          if (this.selectedValues[key].length != 0) {
            this.initialValues[key] = this.selectedValues[key];
          }
        });
      }

      if (isFilterSelected || savedFilter) await this.updateFilterValues(id);

      this.isFilterLoading = false;
    },

    checkFilters() {
      let filter = (store as any).state.cargoRollFilter.cargoRollFilter[
        this.planId
      ];
      if (filter) {
        filter = JSON.parse(filter);
        this.properties.forEach((element: any) => {
          let key = element.id;
          if (key in filter) this.selectedValues[key] = filter[key];
        });
      }
      return filter;
    },

    async getFilterValues() {
      const { data } = await api.get("PlanCargoRoll/getFilterValues", {
        params: {
          planId: this.planId,
        },
      });
      this.properties = data;
    },

    async updateFilterValues(id: any) {
      let values = {};
      for (const [key, val] of Object.entries(this.selectedValues)) {
        if ((val as any).length != 0) values[key] = val;
      }

      const { data } = await api.get("PlanCargoRoll/updateFilterValues", {
        params: {
          planId: this.planId,
          values: JSON.stringify(values),
        },
      });

      (data as any).forEach((element: any) => {
        let value = [];
        if (element.id in this.selectedValues) {
          value = this.selectedValues[element.id];
        }
        if (element.id == id && value.length != 0) return;
        else
          this.properties.find((x: any) => x.id == element.id).values =
            element.values;
      });
    },
  },

  computed: {
    valuesWasChanged(): any {
      let selectedValuesCopy = {};
      Object.keys(this.selectedValues).filter((key) => {
        if (this.selectedValues[key].length != 0) {
          selectedValuesCopy[key] = this.selectedValues[key];
        }
      });
      return (
        JSON.stringify(this.initialValues) == JSON.stringify(selectedValuesCopy)
      );
    },
  },

  data() {
    return {
      initialValues: {},
      isFilterLoading: false,
      popupVisible: false,
      okButtonOptions: {
        text: "Применить",
        onClick: this.onApply,
      },
      resetButtonOptions: {
        text: "Сбросить",
        onClick: this.resetFilter,
      },
      cancelButtonOptions: {
        text: "Отмена",
        onClick: this.onCancel,
      },
      selectedValues: {},
      properties: [],
    };
  },
});
