import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ModelField-label" }
const _hoisted_2 = {
  key: 0,
  style: {"color":"#f85c50"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTagBox = _resolveComponent("DxTagBox")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxScrollView = _resolveComponent("DxScrollView")!
  const _component_DxToolbarItem = _resolveComponent("DxToolbarItem")!
  const _component_DxPopup = _resolveComponent("DxPopup")!

  return (_openBlock(), _createBlock(_component_DxPopup, {
    visible: _ctx.popupVisible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.popupVisible) = $event)),
    "drag-enabled": true,
    "close-on-outside-click": false,
    "show-title": true,
    width: 600,
    height: "auto",
    shading: true,
    resizeEnabled: true,
    title: "Фильтр по свойствам рулонов"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxScrollView, {
        width: "100%",
        height: "100%"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, (property) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: property.id
                }, [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(property.name), 1),
                  _createVNode(_component_DxTagBox, {
                    class: "ModelField-controlTagBox",
                    dataSource: _ctx.getDataSource(property.values),
                    "display-expr": "valueDescription",
                    "value-expr": "value",
                    value: _ctx.selectedValues[property.id],
                    "onUpdate:value": ($event: any) => ((_ctx.selectedValues[property.id]) = $event),
                    stylingMode: "outlined",
                    "search-enabled": true,
                    "show-clear-button": true,
                    onValueChanged: ($event: any) => (_ctx.changeFilterValues(false, property.id))
                  }, null, 8, ["dataSource", "value", "onUpdate:value", "onValueChanged"])
                ]))
              }), 128))
            ]),
            (!_ctx.valuesWasChanged)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, " При применении фильтра по рулонам, табличные фильтры будут сброшены "))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_DxLoadPanel, {
            visible: _ctx.isFilterLoading,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isFilterLoading) = $event)),
            "show-indicator": true,
            "show-pane": true,
            shading: false,
            "close-on-outside-click": false
          }, null, 8, ["visible"])
        ]),
        _: 1
      }),
      _createVNode(_component_DxToolbarItem, {
        options: _ctx.okButtonOptions,
        widget: "dxButton",
        location: "after",
        toolbar: "bottom",
        disabled: _ctx.valuesWasChanged
      }, null, 8, ["options", "disabled"]),
      _createVNode(_component_DxToolbarItem, {
        options: _ctx.resetButtonOptions,
        widget: "dxButton",
        location: "after",
        toolbar: "bottom",
        disabled: !_ctx.isFilter
      }, null, 8, ["options", "disabled"]),
      _createVNode(_component_DxToolbarItem, {
        options: _ctx.cancelButtonOptions,
        widget: "dxButton",
        location: "after",
        toolbar: "bottom"
      }, null, 8, ["options"])
    ]),
    _: 1
  }, 8, ["visible"]))
}